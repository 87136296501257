import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./Footer.css";
import Strings from "../strings.json";
import URLs from "../URLs.json";

const Footer = () => {
    const navigate = useNavigate();

    const onContactMeTextClick = useCallback(() => {
        navigate("/contactMe");
    }, [navigate]);

    return (
        <footer className={`footer-wrapper`}>
            <div className="footer">
                <h1 className="name-header">{Strings.firstLast}</h1>
                <div className="footer-content">
                    <div className="footer-links">
                        <div className="footer-links-list">
                            <div className="links">{Strings.links}</div>
                            <div className="github">{Strings.githubLink}</div>
                            <a className="linkedin" href={URLs.linkedin}>{Strings.linkedinLink}</a>
                        </div>
                        <div className="footer-support-list">
                            <div className="support">{Strings.support}</div>
                            <div className="report-bug">{Strings.reportBug}</div>
                            <div className="suggestions">{Strings.suggestions}</div>
                        </div>
                    </div>
                </div>
                <div className="contact-me-submit-wrapper">
                    <button className="contact-me-submit" onClick={onContactMeTextClick}>{Strings.contact}</button>
                </div>
            </div>
        </footer>
    );
};

Footer.propTypes = {
    className: PropTypes.string,
};

export default Footer;
