import PropTypes from "prop-types";
import "./Professional.css";
import Strings from "../strings.json";

const Professional = ({ className = "" }) => {
    return (
        <div className="professional-container">

            <div className="professional-info-container">
                <h1 className="professional-header">{Strings.professional}</h1>
                <p className="professional-info">
                    {Strings.professionalTitle}
                </p>
                <p className="professional-info">&nbsp;</p>
                <p className="professional-info">
                    {Strings.professionalInfo1}
                </p>
                <p className="professional-info">&nbsp;</p>
                <p className="professional-info">
                    {Strings.professionalInfo2}
                </p>
            </div>
        </div>
    );
};

Professional.propTypes = {
    className: PropTypes.string,
};

export default Professional;
