import PropTypes from "prop-types";
import "./Certifications.css";

const Certifications = ({ className = "" }) => {
    return (
        <section className={`screenshot-cluster ${className}`}>
            <div className="azure-fund-container">
                <div className="azure-fundamentals-parent">
                    <h1 className="azure-fundamentals-header">Azure Fundamentals</h1>
                    <div className="azure-fundamentals-details">
                        <div className="microsoft-certification">
                            Microsoft certification
                        </div>
                        <div className="date-recieved">Received april 8th, 2023</div>
                    </div>
                    <div className="fund-credential-id">
                        <p className="azure-developer">{`Credential ID: 62CC5C817A15F0F6 `}</p>
                        <p className="azure-developer">{`Certification number: 4D58CF-9676F5 `}</p>
                    </div>
                </div>
                <img
                    className="icon"
                    loading="lazy"
                    alt=""
                    src="/microsoftcertifiedfundamentalsbadge-1.svg"
                />
            </div>
            <div className="azure-associate-container">
                <img
                    className="icon"
                    loading="lazy"
                    alt=""
                    src="/microsoftcertifiedassociatebadge-1.svg"
                />
                <div className="azure-associate-details">
                    <h1 className="azure-developer-associate-header">
                        <p className="azure-developer">Azure Developer</p>
                        <p className="azure-developer">Associate</p>
                    </h1>
                    <div className="azure-associate-certification">
                        <div className="microsoft-certification">
                            Microsoft certification
                        </div>
                        <div className="date-recieved">
                            <p className="azure-developer">Received January 12th, 2024</p>
                            <p className="azure-developer">Expires January 12th, 2026</p>
                        </div>
                    </div>
                    <div className="associate-id">
                        <div className="credential-ids">
                            <p className="azure-developer">Credential ID: 89D5C180122188B</p>
                            <p className="azure-developer">
                                Certification number: 996A4A-5E960V
                            </p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

Certifications.propTypes = {
    className: PropTypes.string,
};

export default Certifications;
