import PropTypes from "prop-types";
import "./Certs.css";
import Certifications from "../components/Certifications";

const Certs = ({ className = "" }) => {
    return (
        <div className="certs">
            <Certifications />
        </div>
    );
};

Certs.propTypes = {
    className: PropTypes.string,
};

export default Certs;
