import { useEffect } from "react";
import {
  Routes,
  Route,
  useLocation
} from "react-router-dom";
import Home from "./pages/Home";
import Certs from "./pages/Certs";
import AboutMe from "./pages/AboutMe";
import ContactMe from "./pages/ContactMe";
import Layout from "./components/Layout";

function App() {
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
      case "/certs":
        title = "";
        metaDescription = "";
        break;
      case "/aboutme":
        title = "";
        metaDescription = "";
        break;
      case "/contactMe":
        title = "";
        metaDescription = "";
        break;
      default:
        title = "";
        metaDescription = "";
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Layout>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/certs" element={<Certs />} />
        <Route path="/aboutme" element={<AboutMe />} />
        <Route path="/contactMe" element={<ContactMe />} />
      </Routes>
    </Layout>
  );
}

export default App;