import PropTypes from "prop-types";
import "./Skills.css";
import Strings from "../strings.json";

const Skills = ({ className = "" }) => {
    return (
        <div className="skills">
            <h1 className="skills-header">skills</h1>
            <div className="skills-icons">
                <div className="skill-icons-container">
                    <div className="skill-icons-1">
                        <img
                            className="java-icon"
                            loading="lazy"
                            alt=""
                            src="/java-logo.png"
                        />
                        <img
                            className="react-icon"
                            loading="lazy"
                            alt=""
                            src="/react-logo-1@2x.png"
                        />
                    </div>
                    <div className="skill-icons-2">
                        <img
                            className="node-icon"
                            loading="lazy"
                            alt=""
                            src="/node-logo-1@2x.png"
                        />
                    </div>
                    <div className="skill-icons-3">
                        <img
                            className="azure-icon"
                            loading="lazy"
                            alt=""
                            src="/azure logo.png"
                        />
                    </div>
                    <div className="skill-icons-4">
                        <img
                            className="git-icon"
                            loading="lazy"
                            alt=""
                            src="/gitlogopngtransparent1-1@2x.png"
                        />
                        <img
                            className="github-icon"
                            loading="lazy"
                            alt=""
                            src="/githublogo-1@2x.png"
                        />
                    </div>
                </div>
                <div className="skill-description-container">
                    <ul className="skills-description">
                        <li className="java-script-">{Strings.java}</li>
                        <li className="java-script-">{Strings.javascript}</li>
                        <li className="java-script-">{Strings.nodejs}</li>
                        <li className="java-script-">{Strings.react}</li>
                        <li className="java-script-">{Strings.azure}</li>
                        <li className="java-script-">{Strings.azurePowerApps}</li>
                        <li className="java-script-">{Strings.git}</li>
                        <li className="java-script-">{Strings.github}</li>
                        <li className="java-script-">{Strings.qATesting}</li>
                        <li className="java-script-">{Strings.c}</li>
                        <li className="java-script-">{Strings.linux}</li>
                        <li className="java-script-">{Strings.windows}</li>
                        <li className="java-script-">{Strings.agile}</li>
                        <li className="java-script-">{Strings.kanban}</li>
                    </ul>
                </div>
            </div>
        </div>
    );
};

Skills.propTypes = {
    className: PropTypes.string,
};

export default Skills;
