import React, { useRef } from "react";
import PropTypes from "prop-types";
import "./Email.css";
import emailjs from '@emailjs/browser';
import { NotificationContainer, NotificationManager } from 'react-notifications';
import 'react-notifications/lib/notifications.css';

const Email = () => {

    const form = useRef();

    const sendEmail = (e) => {
        e.preventDefault();

        console.log(form.current);

        emailjs
            .sendForm('service_5lugxer', 'template_1xlvkzn', form.current, {
                publicKey: 'WSzfA-0i-EUVxcGTQ',
            })
            .then(
                () => {
                    NotificationManager.success('Email Send Successfully');
                    e.target.reset();
                },
                (error) => {
                    NotificationManager.error('Error In Sending Email. Please Try Again');
                    e.target.reset();
                },
            );
        e.target.reset();
    };

    return (
        <section className={`email`}>
            <NotificationContainer />
            <div className="email-container">
                <div className="email-parent">
                    <h1 className="email-header">Email Me</h1>
                    <form ref={form} className="email-form --form-control --card --flex-center --dir-column" onSubmit={sendEmail}>
                        <textarea
                            cols="50"
                            type="text"
                            placeholder="Name"
                            name="user_name"
                            required
                        />
                        <div className="email-form-divider">&nbsp;</div>
                        <textarea
                            cols="50"
                            type="text"
                            placeholder="Email Address"
                            name="user_email"
                            required
                        // hidden
                        />
                        <div className="email-form-divider">&nbsp;</div>
                        <textarea
                            cols="50"
                            placeholder="Subject"
                            type="text"
                            name="subject"
                            required
                        />
                        <div className="email-form-divider">&nbsp;</div>
                        <textarea
                            rows="4"
                            placeholder="Message"
                            cols="50"
                            name="message"
                            required
                        />

                        <div className="send-email-submit-wrapper">
                            <button className="send-email-submit" type="submit">Send Email</button>
                        </div>
                    </form>

                </div>
            </div>
        </section>
    );
};

Email.propTypes = {
    className: PropTypes.string,
};

export default Email;
