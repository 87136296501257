// components/Layout.js
import React from 'react';
import NavBar from './NavBar';
import Footer from './Footer';
import './Layout.css';

function Layout({ children }) {
    return (
        <div className="app-container">
            <NavBar />
            <main>
                {children}
            </main>
            <Footer />
        </div>
    );
}

export default Layout;
