import PropTypes from "prop-types";
import "./Experience.css";
import strings from '../strings.json'

const Experince = ({ className = "" }) => {
    return (
        <section className={`experience ${className}`}>
            <div className="experience-header">
                <h1 className="experience">{strings.experience}</h1>
            </div>
            <div className="experience-list">
                <div className="neudesic-experience">
                    <div className="neudesic-details">
                        <h2 className="neudesic">{strings.neudesic}</h2>
                        <div className="job-title">
                            {strings.title}
                        </div>
                    </div>
                </div>
                <div className="project-title">
                    {strings.project1}
                </div>
                <ul className="project-details">
                    <li>
                        {strings.project1Details}
                    </li>
                </ul>
                <div className="project-title">
                    {strings.project2}
                </div>
                <ul className="project-details">
                    <li>
                        {strings.project2Details}
                    </li>
                </ul>
            </div>
        </section>
    );
};

Experince.propTypes = {
    className: PropTypes.string,
};

export default Experince;
