import Experince from "../components/Experience";
import Education from "../components/Education";
import Skills from "../components/Skills";
import "./Home.css";
import Strings from "../strings.json"
import { useEffect } from "react";

const Home = () => {

    const API_URL = "http://localhost:5177/"

    const refreshNotes = async () => {
        const response = await fetch(API_URL + "api/MBCOM/GetNoes");
        const data = await response.json();
        console.log(data);
    }

    useEffect(() => {
        refreshNotes();
    }, []);





    return (
        <div className="home-container">
            <div className="home">
                <div className="header">
                    <div className="name-container">
                        <h1 className="firstLastName">
                            <p className="fristName">{Strings.firstName}</p>
                            <p className="lastName">{Strings.lastName}</p>
                        </h1>
                        <div className="title-container">
                            <h2 className="titles">
                                <p className="title">{Strings.titleOne}</p>
                                <p className="title">{Strings.titleTwo}</p>
                                <p className="title">{Strings.titleThree}</p>
                            </h2>
                        </div>
                    </div>
                </div>
                <div className="profile-image">
                    <img
                        className="headshot-icon"
                        loading="lazy"
                        alt=""
                        src="/headshot@2x.png"
                    />
                </div>
            </div>
            <Experince />
            <Education />
            <Skills />
            <div>
                <button className="download-resume-button">
                    <a href="https://mbcomstorage.blob.core.windows.net/pdffiles/resume.pdf" download target="_blank" rel="noreferrer">Dowload Resume</a>
                </button>
            </div>
        </div>
    );
};

export default Home;
