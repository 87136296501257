import Personal from "../components/Personal";
import Professional from "../components/Professional";
import "./AboutMe.css";

const AboutMe = () => {
    return (
        <div className="aboutme">
            <h1 className="aboutme-header">About Me</h1>
            <section className="aboutme-section">
                <Professional />
                <Personal />
            </section>
        </div>
    );
};

export default AboutMe;
