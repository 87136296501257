import { useCallback } from "react";
import { useNavigate } from "react-router-dom";
import PropTypes from "prop-types";
import "./NavBar.css";
import strings from '../strings.json'

const NavBar = ({ className = "" }) => {
    const navigate = useNavigate();

    const onAboutMeTextClick = useCallback(() => {
        navigate("/aboutme");
    }, [navigate]);

    const onCertificationsTextClick = useCallback(() => {
        navigate("/certs");
    }, [navigate]);

    const onContactMeTextClick = useCallback(() => {
        navigate("/contactMe");
    }, [navigate]);

    return (
        <section className={`content ${className}`}>
            <header className={`navbar ${className}`}>
                <nav className="navbar-links">
                    <a className="home1" href="/">{strings.home}</a>
                    <a className="about-me" href="/aboutme" onClick={onAboutMeTextClick}>
                        {strings.aboutMe}
                    </a>
                    <a className="certifications" href="/certs" onClick={onCertificationsTextClick}>
                        {strings.cert}
                    </a>
                    <a className="about-me" href="/contactMe" onClick={onContactMeTextClick}>
                        {strings.contact}
                    </a>
                </nav>
            </header>
        </section>
    );
};

NavBar.propTypes = {
    className: PropTypes.string,
};

export default NavBar;
