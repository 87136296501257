import PropTypes from "prop-types";
import "./Personal.css";
import Strings from "../strings.json";

const Personal = ({ className = "" }) => {
    return (
        <div className={`personal`}>
            <div className="personal-container">
                <h1 className="personal-header">{Strings.personal}</h1>
                <div className="hobbies-header">{Strings.hobbies}</div>
                <div className="computer-building">{Strings.computerBuilding}</div>
                <div className="computer-building-info">{`I was 13 when I built my first computer. I learned the process of building a computer by watching you tube videos. Once all my components showed up, I went through the process of building it with my Father. This is when I fell in love with computer and everything involved with computers. Since then I have built over half a dozen computers for me and my friends. `}</div>
                <div className="computer-container">
                    <div className="current-components">Current Components</div>
                    <div className="components-list">
                        <ul className="list-set">
                            <li >Intel i9-10850k</li>
                            <li >
                                ASUS ROG STRIX Z490-E Gaming
                            </li>
                            <li >64gb x 3200 Mhz</li>
                            <li >Gigabyte RTX 4080</li>
                            <li >Corsair H100x AIO</li>
                            <li >
                                Corsair Crystal 570X ATX Mid-Tower Case
                            </li>
                            <li >
                                6x Corsair LL120 Case Fans
                            </li>
                            <li >
                                2x 500gb Samsung 970 M.2 NVME
                            </li>
                            <li>2TB Western Digital HDD</li>
                        </ul>
                        <ul className="list-set">
                            <li >2TB Crucial SSD</li>
                            <li >1TB Crucial M.2 NVME</li>
                            <li >LG UltraGear FHD 27-Inch</li>
                            <li >Dell TN 27-Inch</li>
                            <li >HP 27-Inch</li>
                            <li >Keychron Q6 Pro</li>
                            <li >Razer Deathadder V2 Pro</li>
                            <li >
                                Edifier Bookshelf Speakers
                            </li>
                            <li>Elgato Wave 3 Microphone</li>
                        </ul>
                    </div>
                </div>
            </div>
        </div >
    );
};

Personal.propTypes = {
    className: PropTypes.string,
};

export default Personal;
