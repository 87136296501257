import PropTypes from "prop-types";
import "./Education.css";
import Strings from "../strings.json";

const Education = ({ className = "" }) => {
    return (
        <section className={`education ${className}`}>
            <div className="education-header">
                <h1 className="education-title">{Strings.Education}</h1>
            </div>
            <img
                className="asu-logo-1-icon"
                loading="lazy"
                alt=""
                src="/asulogo-1@2x.png"
            />

            <div className="education-details">
                <h2 className="asu">
                    <p className="asu-details">
                        {Strings.ASU}
                    </p>
                    <p className="asu-details">
                        {Strings.CS}
                    </p>
                    <p className="asu-details">
                        {Strings.BS}
                    </p>
                    <p className="asu-details">
                        {Strings.Grad}
                    </p>
                </h2>

            </div>
            <h2 className="capstone-title">
                {Strings.Capstone}
            </h2>
            <div className="capstone-details">
                <ul className="capstone-details-list">
                    <li className="capstone-info">
                        {Strings.CapstoneDataOne}
                    </li>
                    <li className="capstone-info">
                        {Strings.CapstoneDataTwo}
                    </li>
                </ul>

                <div className="capstone-image">
                    <img
                        className="psyche-icon"
                        loading="lazy"
                        alt=""
                        src="/psychespacecraftasteroidcompositescaled-1@2x.png"
                    />
                    <div className="image-caption">
                        Psyche spacecraft and asteroid
                    </div>
                </div>
            </div>


        </section>
    );
};

Education.propTypes = {
    className: PropTypes.string,
};

export default Education;
