import PropTypes from "prop-types";
import "./Certs.css";
import Email from "../components/Email";

const ContactMe = ({ className = "" }) => {
    return (
        <div className="certs">
            <Email />
        </div>
    );
};

ContactMe.propTypes = {
    className: PropTypes.string,
};

export default ContactMe;
